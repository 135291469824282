import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import CoinIcon from '../../assets/MobileMenu/coin.svg';
import withCountry from '../../components/HigherOrder/withCountry';
import { GridItems, HorizontalItemsScroll, ItemsScrollDefaultHeader } from '../../components/ItemsScroll';
import Skeleton from '../../components/Skeleton';
import { FlatButton } from '../../components/UI/Button/styles';
import { useAuth } from '../../hooks/auth';
import { useHome } from '../../hooks/home';
import { useQuests } from '../../hooks/quests';
import { AdvertiserListItem } from '../../models/home';
import { CompanyQuestsPreview, QuestByCompanySlug } from '../../models/quests';
import { getExtensionVersion, isAppV2 } from '../../services/versionManager';
import { Banner } from '../../styles/pages/home/styles';
import theme from '../../styles/theme';

import joinCointimesDiscordPtBrBanner from '../../assets/Banners/join-discord-pt-br.png';
import joinKannaAirdropPtBrBanner from '../../assets/Banners/join-kanna-airdrop-pt-br.png';
import joinTelegramEnBanner from '../../assets/Banners/join-telegram-en.png';
import joinTelegramPtBrBanner from '../../assets/Banners/join-telegram-pt-br.png';
import joinXtimesAirdropEnBanner from '../../assets/Banners/join-xtimes-airdrop-en.png';
import joinXtimesAirdropPtBrBanner from '../../assets/Banners/join-xtimes-airdrop-pt-br.png';
import xtimesSeason2HasStartedEnBanner from '../../assets/Banners/xtimes-airdrop-season2-started-en.png';
import xtimesSeason2HasStartedPtBrBanner from '../../assets/Banners/xtimes-airdrop-season2-started-pt-br.png';

import { isOnPopQuest } from '../../utils/app';
import i18n from "../../i18n";
import Markdown from "markdown-to-jsx";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

const IssueReport: React.FC<{ title: string, body: string }> = ({title, body}) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <>

        <div>
          <button style={{ color: 'red', display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }} onClick={() => setShowContent(!showContent)}>
            <FAIcon icon={showContent ? faMinusSquare : faPlusSquare} color="white" />
            <Markdown>{title}</Markdown>
          </button>
          {showContent && <Markdown style={{ padding: '10px', marginLeft: '10px' }}>{body}</Markdown>}
        </div>
    </>
  )
}

const issues: {title: string, body: string}[] = [
  {
    title: '### Continua a manutenção para garantir os saldos corretos ℹ️',
    body: `
- Parte do problema foi solucionado, porém a manutenção continua para garantir a corretude do sistema.
- **Nenhum** BTC adquirido anteriormente será perdido! ⚠️
- **Nenhum** TON adquirido será perdido! ⚠️
- Os saques permanecerão desativados até a solução completa do problema. ℹ️
<br/><br/>
**ATENÇÃO!**: Pedidos de saques abertos na tentativa de explorar o problema, foram cancelados! 👈
`
  },
  {
    title: '###  Pontuações do Airdrop foram corrigidas ℹ️',
    body: `
- Houve um incidente que ocasionou muitos pontos sendo adquiridos por conta do problema com o saldo de BTC.
- Todos os pontos indevidos foram removidos e o rank voltou a sua normalidade.`
  }
]

const IssueTracker: React.FC = () => {
  return (
    <>
      <div style={{ padding: '0px 20px' }}>
        <div style={{ backgroundColor: 'black', color: '#fe501c', padding: '20px', borderRadius: '20px', border: '2px solid #FE501C', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {issues.map((v, i) => <IssueReport key={i} title={v.title} body={v.body} />)}
        </div>
      </div>
    </>
  )
}

const NewHome: React.FC = () => {
  const router = useRouter();
  const { localCountry } = useAuth();

  const { getAdvertisers } = useHome();
  const [featuredStores, setFeaturedStores] = useState<AdvertiserListItem[]>([]);
  const [canDisplayStores, setCanDisplayStores] = useState<boolean>(false);

  const [isLoadingFeaturedStores, setIsLoadingFeaturedStores] = useState(true);
  const [currentLoadingStoreIndex, setCurrentLoadingStoreIndex] = useState(null);

  const { getCompanyQuestPreviews, getQuestsByCompanySlug } = useQuests();
  const [companyQuestPreviews, setCompanyQuestPreviews] = useState<CompanyQuestsPreview[]>([]);
  const [isCompanyQuestPreviews, setIsCompanyQuestPreviews] = useState(true);

  const [readToEarnList, setReadToEarnList] = useState<QuestByCompanySlug[]>([]);

  const [isLoadingReadToEarnList, setIsLoadingReadToEarnList] = useState(true);
  const [isLoadingMoreReadToEarnList, setIsLoadingMoreReadToEarnList] = useState(false);

  const [readToEarnLoadingCount, setReadToEarnLoadingCount] = useState(20);

  const isKannaLaunched = new Date() >= new Date(2024, 8, 13);

  const bannersTop = isKannaLaunched ?
    [
      {
        image: localCountry == 'BR' ? xtimesSeason2HasStartedPtBrBanner : xtimesSeason2HasStartedEnBanner,
        link: localCountry == 'BR' ? '/airdrop/xtimes/season' : '/airdrop/xtimes/season',
        backgroundColor: '#000000'
      },
      {
        image: localCountry == 'BR' ? joinKannaAirdropPtBrBanner : xtimesSeason2HasStartedEnBanner,
        link: localCountry == 'BR' ? '/airdrop/kanna' : '/airdrop/xtimes/season',
        backgroundColor: '#000000'
      },
    ]
    :
    [
      {
        image: localCountry == 'BR' ? joinXtimesAirdropPtBrBanner : joinXtimesAirdropEnBanner,
        link: '/airdrop/xtimes/season',
        backgroundColor: '#000000'
      },
    ];

  // const mobileBannerTop = {
  //   image: 'https://cointimes.com.br/wp-content/uploads/2023/11/comunidade_whatsapp.jpeg',
  //   link: 'https://chat.whatsapp.com/GmfEVpGlZ0jJpws2Sjc21b',
  //   backgroundColor: '#a3fac5'
  // };

  function getRandomBannerTop() {
    return bannersTop[Math.floor(Math.random() * bannersTop.length)]
  }

  const [bannerTopUrl, setBannerTopUrl] = useState(getRandomBannerTop());

  const [extensionVersion, setExtensionVersion] = useState(null);

  useEffect(() => {
    if (!isAppV2()) {
      return;
    }

    window.addEventListener('onPopInAppPage', handleOnPopInAppPage);

    return () => {
      window.removeEventListener('onPopInAppPage', handleOnPopInAppPage);
    };
  }, []);

  const handleOnPopInAppPage = (event) => {
    if (isOnPopQuest(event)) {
      setReadToEarnList(prevReadToEarnList => {
        const newReadToEarnList = [...prevReadToEarnList];
        const index = newReadToEarnList.findIndex(readToEarn => readToEarn.id === event.detail.dataFromPage.quest.id);

        if (index !== -1) {
          newReadToEarnList[index].is_rewarded = event.detail.dataFromPage.quest.is_rewarded;
          setReadToEarnList(newReadToEarnList);
        }

        return newReadToEarnList;
      });
    }
  };

  useEffect(() => {
    async function getAdvertisersInitialData() {
      try {
        const featuredStoresData = await getAdvertisers(19, 0);

        setFeaturedStores(featuredStoresData ?? []);
        setIsLoadingFeaturedStores(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (localCountry === 'BR') {
      setCanDisplayStores(true);
      getAdvertisersInitialData();
    }
  }, [localCountry]);

  useEffect(() => {
    getExtensionVersion().then((value) => {
      setExtensionVersion(value);
    });
  }, []);

  useEffect(() => {
    async function getCompanyQuestPreviewsInitialData() {
      try {
        const companyQuestPreviews = await getCompanyQuestPreviews();

        setCompanyQuestPreviews(companyQuestPreviews ?? []);
        setIsCompanyQuestPreviews(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    setBannerTopUrl(getRandomBannerTop());

    // const isMobile = localStorage.getItem('@coingoback:isMobile') === 'true';
    // if (isMobile) {
    //   setBannerTopUrl(mobileBannerTop);
    // }else{
    //   setInterval(() => {
    //       setBannerTopUrl(getRandomBannerTop());
    //   }, 8000);
    // }

    getCompanyQuestPreviewsInitialData();
  }, []);

  useEffect(() => {
    if (router.isReady && readToEarnList.length === 0) {
      // let page = 1;

      // if(!Array.isArray(router.query.page) && isAppV2()) {
      //   page = parseInt(router.query.page);
      // }

      const limit = 200;

      setReadToEarnLoadingCount(limit);
      getNewsInitialData(limit, 0);
    }
  }, [router.isReady]);

  async function getNewsInitialData(limit?: number, offset?: number) {
    try {
      const readToEarnList = await getQuestsByCompanySlug('read-to-earn', limit, offset);

      setReadToEarnList(readToEarnList ?? []);
      setIsLoadingReadToEarnList(false);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  }

  // async function loadMoreNewsData() {
  //   try {
  //     setIsLoadingMoreReadToEarnList(true);

  //     const loadedMoreReadToEarnList = await getQuestsByCompanySlug('read-to-earn', 20, readToEarnList.length);
  //     setReadToEarnList(readToEarnList.concat(loadedMoreReadToEarnList));

  //     setIsLoadingMoreReadToEarnList(false);
  //   } catch (error) {
  //     console.log(error);
  //     Sentry.captureException(error);
  //   }
  // }

  const handleStoreClick = (slug: string) => {
    router.push({
      pathname: `store/${slug}`,
    });
  };

  const handleQuestCompanyClick = (slug: string) => {
    router.push({
      pathname: `quests/${slug}`,
    });
  };

  useEffect(() => {
    // Dynamically load the GPT library
    const script = document.createElement('script');
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(function () {
        window.googletag.defineSlot('/21958300909/cointimes.com.br/cointimes_mobile_horizontal_top_economia', [[320, 50], [300, 100]], 'div-gpt-ad-1709682267168-0').addService(window.googletag.pubads());
        window.googletag.defineSlot('/21958300909/cointimes.com.br/cointimes_mobile_horizontal_top_blog', [[300, 100], [320, 50]], 'div-gpt-ad-1709682199466-0').addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
      });

      window.googletag.cmd.push(function () { window.googletag.display('div-gpt-ad-1709682267168-0'); });
      window.googletag.cmd.push(function () { window.googletag.display('div-gpt-ad-1709682199466-0'); });
    };
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Head>
        <title>{t('pages.home.earn-bitcoin')} | Cointimes</title>
        <meta
          name="description"
          content={t('pages.home.earn-bitcoin')}
        />
      </Head>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100vw', gap: 40, margin: '20px 0px 20px 0px', WebkitTapHighlightColor: 'transparent' }}>

        { i18n.language === 'pt-BR' && <IssueTracker /> }
        <Banner backgroundColor={bannerTopUrl.backgroundColor}>
          <a target="_blank" href={bannerTopUrl.link}>
            <img
              src={bannerTopUrl.image.src}
              alt="banner"
            />
          </a>
        </Banner>
        {/* <Banner backgroundColor={'#aaa6'}>
          <div id='div-gpt-ad-1709682267168-0' style={{ minWidth: '300px', minHeight: '50px', textAlign: 'center' }}></div>
        </Banner> */}
        <HorizontalItemsScroll
          header={(props) =>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <ItemsScrollDefaultHeader
                elements={props.elements}
                headerText={t('pages.home.complete-quests-and-earn')}
                headerSeeAllColor={theme.colors.primary.main}
              />
            </div>
          }
          gap={15}
        >
          {isCompanyQuestPreviews ?
            Array.from(Array(15)).map((_, idx) => {
              return <Skeleton
                key={idx}
                style={{
                  minHeight: 80,
                  minWidth: 80,
                  borderRadius: '50%',
                  marginLeft: idx === 0 ? 20 : 0,
                  marginRight: idx === 14 ? 20 : 0,
                }}
              ></Skeleton>
            }) :

            companyQuestPreviews.map((companyQuestPreview, idx) => {
              if (companyQuestPreview.id === 'befc36ba-b1a1-4c00-aa28-7045b1f8ddec' && !isKannaLaunched) {
                return
              }

              return <button
                key={idx}
                style={{
                  marginLeft: idx === 0 ? 20 : 0,
                  marginRight: idx === companyQuestPreviews.length - 1 ? 20 : 0,
                }}
                onClick={() => {
                  if (companyQuestPreview.id === '6891bf0b-b499-4846-aa8f-da29e0ee6700') {
                    router.push('/airdrop/xtimes/season');
                  } else if (companyQuestPreview.id === 'befc36ba-b1a1-4c00-aa28-7045b1f8ddec') {
                    router.push('/airdrop/kanna');
                  } else if (companyQuestPreview.slug === 'read-to-earn') {
                    router.push('/read-to-earn');
                  } else {
                    handleQuestCompanyClick(companyQuestPreview.slug);
                  }
                }}
              >
                <div style={{
                  minHeight: 80,
                  minWidth: 80,
                  borderRadius: '50%',
                  border: 'solid black 2px',
                  backgroundImage: "url('" + companyQuestPreview.image_small + "')",
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}>
                </div>
              </button>
            })
          }
        </HorizontalItemsScroll>

        {canDisplayStores ?

          <HorizontalItemsScroll
            header={(props) =>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <ItemsScrollDefaultHeader
                  elements={props.elements}
                  headerText={t('pages.home.buy-and-earn')}
                  headerSeeAllColor={theme.colors.primary.main}
                  onSeeAllClick={() => {
                    router.push('/stores');
                  }}
                />
              </div>
            }
            gap={15}
          >
            {isLoadingFeaturedStores ?
              Array.from(Array(10)).map((_, idx) => {
                return <Skeleton
                  key={idx}
                  style={{
                    minHeight: 190,
                    minWidth: 200,
                    marginLeft: idx === 0 ? 20 : 0,
                    marginRight: idx === 9 ? 20 : 0,
                    borderRadius: '15px',
                  }}
                ></Skeleton>

              }) :

              featuredStores.map((featuredStore, idx) => {
                return <div
                  key={idx}
                  style={{
                    minHeight: 190,
                    minWidth: 200,
                    marginLeft: idx === 0 ? 20 : 0,
                    marginRight: idx === featuredStores.length - 1 ? 20 : 0,
                    borderRadius: '15px',
                    border: 'solid black 2px',
                    padding: 10,
                  }}
                ><button
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                  onClick={() => {
                    setCurrentLoadingStoreIndex(idx), handleStoreClick(featuredStore.slug);
                  }}
                >
                    <div style={{
                      minHeight: 100,
                      borderRadius: '15px',
                      border: 'solid black 2px',
                      backgroundImage: "url('" + featuredStore.image_url + "')",
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      width: '100%'
                    }}></div>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                      {currentLoadingStoreIndex === idx ?
                        <CircularProgress size={20} style={{ color: 'black' }} />
                        :
                        <>
                          <span style={{ color: 'black' }}>{featuredStore.name}</span>
                          <h3 style={{ color: 'black' }}>{featuredStore.commission_description}</h3>
                        </>
                      }
                    </div>
                  </button>
                </div>
              })
            }
          </HorizontalItemsScroll>

          : <></>}

        {localCountry == 'BR' ?
          <Banner backgroundColor={'#000000'}>
            <a target="_blank" href="https://discord.gg/cointimes">
              <img
                src={joinCointimesDiscordPtBrBanner.src}
                alt="banner"
              />
            </a>
          </Banner>
          : <Banner backgroundColor={'#000000'}>
            <a target="_blank" href="https://t.me/cointimesapp">
              <img
                src={localCountry == 'BR' ? joinTelegramPtBrBanner.src : joinTelegramEnBanner.src}
                alt="banner"
              />
            </a>
          </Banner>
        }

        {/* <Banner backgroundColor={'#aaa6'}>
          <div id='div-gpt-ad-1709682199466-0' style={{ minWidth: '300px', minHeight: '50px' }}></div>
        </Banner> */}

        <GridItems
          header={(props) =>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <ItemsScrollDefaultHeader
                elements={props.elements}
                headerText={t('pages.home.daily-read-to-earn')}
              />
            </div>
          }
          gap={15}
          gridMinPx={300}
          padding='0px 15px'
        >
          {isLoadingReadToEarnList && readToEarnLoadingCount ?
            Array.from(Array(readToEarnLoadingCount)).map((_, idx) => {
              return <div
                key={idx}
                style={{
                  minHeight: 300,
                  height: 300,
                  width: '100%',
                }}>
                <Skeleton style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '15px',
                }}></Skeleton>
              </div>
            }) :

            readToEarnList.map((readToEarn) => {
              return <div
                key={readToEarn.id}
                style={{
                  minHeight: 300,
                  height: 300,
                  width: '100%',
                  borderRadius: '15px',
                  border: 'solid black 2px',
                  padding: 10,
                }}
              ><button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={() => {
                  if (isAppV2()) {
                    window.location.href = '/open-in-app/coingoback-quest-navigation/quests/' + readToEarn.id;
                  } else if (extensionVersion !== null) {
                    if (extensionVersion === '2.3.2') {
                      window.open(readToEarn.quest_link.initial_url, '_blank');
                    } else {
                      window.open(`${process.env.NEXT_PUBLIC_API_URL.replace('/graphql', '')}/enable-quest?quest_id=${readToEarn.id}`, '_blank');
                    }
                  }
                }}
              >
                  <div style={{
                    minHeight: 150,
                    borderRadius: '15px',
                    border: 'solid black 2px',
                    backgroundImage: "url('" + readToEarn.image + "')",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    width: '100%'
                  }}></div>
                  <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: 8 }}>
                    <h3 style={{ marginTop: 20, textAlign: 'left', color: 'black' }}>{readToEarn.title.substring(0, 69) + (readToEarn.title.length > 70 ? '...' : '')}</h3>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ border: 'solid black 1px', borderRadius: '8px', padding: '5px 10px', display: 'flex', alignItems: 'center', backgroundColor: readToEarn.is_rewarded === true ? 'white' : theme.colors.primary.main }}>
                        {readToEarn.reward_currency === 'BTC' ?
                          <CoinIcon style={{ filter: 'brightness(0)', marginRight: 3, width: 12, height: 12 }} />
                          : <></>}
                        <span style={{ color: 'black' }}>{readToEarn.reward_amount_formatted}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            }).concat(
              isLoadingMoreReadToEarnList ? Array.from(Array(20)).map((_, idx) => {
                return <div
                  key={idx}
                  style={{
                    minHeight: 300,
                    height: 300,
                    width: '100%',
                  }}
                >
                  <Skeleton style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '15px',
                  }}></Skeleton>
                </div>
              }) : []
            )
          }
        </GridItems>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0px 15px' }}>
          <div
            style={{
              minHeight: 300,
              height: 300,
              width: '100%',
              borderRadius: '15px',
              border: 'solid black 2px',
              padding: 10,
              backgroundColor: 'black',
            }}
          ><button
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
              <h1 style={{ marginTop: 20, textAlign: 'center', color: '#F44805' }}>{t('pages.read-to-earn.wow-title')}</h1>
              <h3 style={{ marginTop: 20, textAlign: 'center', color: 'white' }}>{t('pages.read-to-earn.daily-read-to-earn-finished')}</h3>
              <h4 style={{ marginTop: 10, textAlign: 'center', color: 'white', fontWeight: 'normal' }}>{t('pages.read-to-earn.want-to-learn-more')}</h4>

              <FlatButton
                style={{ marginTop: 20, padding: 20 }}
                onClick={() => {
                  window.open(t('pages.read-to-earn.earn-more-link'), '_blank');
                }}
              >
                {t('pages.read-to-earn.i-want-to-earn')}
              </FlatButton>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withCountry(NewHome);
